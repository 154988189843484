import gql from 'graphql-tag';
import AvatarFragment from '@fragments/shared/AvatarFragment';

export default gql`
    fragment PersonSummaryFragment on Person {
        id
        locale
        path
        title
        personTitle
        dek
        firstName
        middleName
        lastName
        suffix
        socialNetworks {
            handle
            id
            network
        }
        personDetails {
            about
            personType
        }
        avatar {
            ...AvatarFragment
        }
        type
        hasProfile
        externalLink {
            label
            url
        }
        isArchived
    }

    ${AvatarFragment}
`;
